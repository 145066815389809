<style lang="scss">
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="product-list">
                <com-list-search>
                    <div slot="list-search-after">
                        <el-button type="primary" @click="dialogFormVisible=true">新增定时器</el-button>
                    </div>
                </com-list-search>

                <el-table
                        :data="data"
                        style="width: 100%">
                    <el-table-column
                            prop="jobName"
                            label="定时器名称"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="jobGroup"
                            label="定时器分组"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="triggerUrl"
                            label="调用url"
                            width="220">
                    </el-table-column>
                    <el-table-column
                            prop="triggerType"
                            label="定时器传输类型">
                    </el-table-column>
                    <el-table-column
                            prop="triggerGroup"
                            label="定时器trigger组">
                    </el-table-column>
                    <el-table-column
                            prop="triggerName"
                            label="定时器trigger名称">
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="delTimer(scope.row)" type="danger" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </section-content>
        </el-main>

        <el-dialog title="新增定时器" :visible.sync="dialogFormVisible">
            <el-form :model="addForm" :rules="rules" ref="addForm">
                <el-form-item label="定时器trigger名称" label-width="160px" prop="triggerName">
                    <el-input v-model="addForm.triggerName" auto-complete="off" style="width: 50%"></el-input>
                </el-form-item>
                <el-form-item label="定时器trigger组" label-width="160px" prop="triggerGroup">
                    <el-input auto-complete="off" v-model="addForm.triggerGroup" style="width: 50%"></el-input>
                </el-form-item>
                <el-form-item label="定时器trigger类型" label-width="160px" prop="triggerType">
                    <el-select placeholder="请选择类型" v-model="addForm.triggerType">
                        <el-option label="ribbon" value="ribbon"></el-option>
                        <el-option label="http" value="http"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="定时器调用url" label-width="160px" prop="triggerUrl">
                    <el-input auto-complete="off" v-model="addForm.triggerUrl" style="width: 50%"></el-input>
                </el-form-item>
                <el-form-item label="定时器trigger表达式" label-width="160px" prop="triggerCronExpression">
                    <el-input auto-complete="off" v-model="addForm.triggerCronExpression" style="width: 50%"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTimer">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import comListSearch from "../../components/comListSearch/index.vue";
    import SectionContent from "@/components/sectionContent/index.vue";

    export default {
        //定义模版数据
        data() {
            return {
                data: [
                    {
                        "cronExpression": "",
                        "jobGroup": "",
                        "jobName": "",
                        "triggerGroup": "",
                        "triggerName": "",
                        "triggerType": "",
                        "triggerUrl": ""
                    }
                ],
                dialogFormVisible: false,
                addForm: {
                    "triggerCronExpression": "",
                    "triggerGroup": "",
                    "triggerName": "",
                    "triggerType": "",
                    "triggerUrl": ""
                },
                rules: {
                    triggerCronExpression: [{required: true, message: '请输入trigger表达式', trigger: 'blur'}],
                    triggerGroup: [{required: true, message: '请输入trigger组名', trigger: 'blur'}],
                    triggerName: [{required: true, message: '请输入trigger名称', trigger: 'blur'}],
                    triggerType: [{required: true, message: '请选择类型', trigger: 'change'}],
                    triggerUrl: [{required: true, message: '请输入trigger调用url', trigger: 'blur'}]
                },
                // httpUrl: "http://192.168.1.17:3000"
                // httpUrl:"http://118.190.166.0:3000"
                httpUrl: "http://118.190.166.0:3000"
            }
        },
        components: {
            SectionContent,
            comListSearch,
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.getData();
        },
        //定义事件方法
        methods: {
            async getData() {
                let res = await this.http(`${this.httpUrl}/galaxyJob/listSchedule`, {
                    pageIndex:1,
                    pageSize:100
                }, 'POST');
                // let res = await this.http("http://192.168.1.26:3000/galaxyJob/listSchedule", '', 'POST');
                console.log(res);
                if (res.success) {
                    this.data = res.data.data;
                } else {
                    this.$message.error(res.errors.message);
                }
            },
            async delTimer(item) {
                let form = {
                    "triggerCronExpression": item.cronExpression,
                    "triggerGroup": item.triggerGroup,
                    "triggerName": item.triggerName,
                    "triggerType": item.triggerType,
                    "triggerUrl": item.triggerUrl
                }
                let res = await this.http(`${this.httpUrl}/galaxyJob/removeTrigger`, form, 'DELETE');
                // let res = await this.http("http://192.168.1.26:3000/galaxyJob/removeTrigger", form, 'DELETE');
                console.log(res);
                if (res.success) {
                    this.$message({
                        showClose: true,
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getData();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.errors.message,
                        type: 'error'
                    });
                }
            },
            addTimer() {
                this.$refs.addForm.validate(async (valid) => {
                    if (valid) {
                        let form = {
                            "triggerCronExpression": this.addForm.triggerCronExpression,
                            "triggerGroup": this.addForm.triggerGroup,
                            "triggerName": this.addForm.triggerName,
                            "triggerType": this.addForm.triggerType,
                            "triggerUrl": this.addForm.triggerUrl
                        }
                        let res = await this.http(`${this.httpUrl}/galaxyJob/addSchedule`, form, 'POST');
                        // let res = await this.http(conf.api_url +"galaxyJob/addSchedule", form, '1');
                        this.dialogVisible = true;
                        console.log(res);
                        if (res.success) {
                            this.$message({
                                showClose: true,
                                message: '添加成功',
                                type: 'success'
                            });
                            this.getData();
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.errors.message,
                                type: 'error'
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }

                });
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
